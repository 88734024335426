import React, { createContext, useContext, useEffect, useRef, useState, useReducer } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Auth } from 'aws-amplify';
import AuthContext from '../auth/AuthContext';
import HubConfigurationFactory from '../../utility/HubConfigurationFactory';
import ProcessContext from '../process/ProcessContext';
import UIContext from '../ui/UIContext';
import { HELP_DESK_LINK } from '../../constants/helpDesk';
import SignalRReducer from './SignalRReducer';
import {SET_SIGNALR_IS_CONNECTED} from '../types/contextTypes';

/**
 * Starts a SignalR connection and retries in case of failure.
 * Retries up to the specified maxRetries, then waits for a defined interval before starting over.
 *
 * @param {object} connection - The SignalR connection instance.
 */
const startSignalRConnection = async (connection, notifySuccess, notifyError, removeError,setSignalRConnectionStatus) => {
    
    const match = connection.baseUrl.match(/\/hubs\/v1\/([^?]+)/);
    let hub = match[1];
    console.log(`Attempting to start SignalR connection to ${hub}...`);
    await connection.start();

    if (connection.state === "Connected") {
        setSignalRConnectionStatus(true); 
        console.log(`Connected successfully to ${hub}`);
    }else{
        console.warn(`Failed to connect to ${connection.baseUrl}`);
        setSignalRConnectionStatus(false);
    }
   

    connection.onreconnecting((error) => {
        setSignalRConnectionStatus(false); 
        console.error(`Connection closed: ${error?.message}`);
        notifyError("Connection lost. Attempting to reconnect… Make sure you have a stable internet connection.");
    });

    connection.onreconnected(() => {
        removeError();
        setSignalRConnectionStatus(true);
        console.log(`Reconnected successfully.`);
        notifySuccess("Connection successfully reestablished");
    });
};


/**
 * Context for managing SignalR connections and providing SignalR methods to components.
 */
const SignalRContext = createContext();

/**
 * SignalRProvider component initializes SignalR connections for the given hubs and provides
 * a context for interacting with SignalR methods.
 *
 * @param {object} props - Component properties.
 * @param {React.ReactNode} props.children - Child components.
 * @param {Array} props.hubConfiguration - Array of hub configurations used for SignalR.
 */
export const SignalRProvider = React.memo(({ children }) => {
    const initialState = {
        signalRIsConnected: false
      };
    const [state, dispatch] = useReducer(SignalRReducer, initialState);
    const connectionsRef = useRef({}); // Holds SignalR connection instances by hub name
    const [accessToken, setAccessToken] = useState(null); // Stores the access token
    const [signalRIsReady, setSignalRIsReady] = useState(false); // Tracks readiness of access token and hubConfiguration
    const [hubConfiguration, setHubConfiguration] = useState(null);
    const { notifyError, notifySuccess } = useContext(ProcessContext);
    const {removeError} = useContext(UIContext);

    const { currentTenant, currentContainer } = useContext(AuthContext);

    const setSignalRConnectionStatus = (isConnected) => {
        dispatch({
          type: SET_SIGNALR_IS_CONNECTED,
          payload: isConnected,
        });
    };
    /*
    * Removes any error messages when the tenant changes.
    */
    useEffect(() => {
        removeError();
    }, [currentTenant]);

    /**
     * Fetches the access token from AWS Amplify and sets it in the state.
     */
    useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                const session = await Auth.currentSession();
                const newToken = session.getAccessToken()?.jwtToken;
                if (newToken !== accessToken) {                   
                    setAccessToken(newToken);
                    console.log("AccessToken updated.");
                } else {
                    console.log("AccessToken is still valid.");
                }
            } catch (err) {
                console.error('Failed to get access token:', err);
            }
        };
        fetchAccessToken(); // Fetch the token when the component mounts
    }, []);

    useEffect(() => {
    const factory = new HubConfigurationFactory({
        tenantId: currentTenant?.tenantId,
        containerId: currentContainer?.id,
        scaleId: currentContainer?.scaleId
    });
    const newConfiguration = factory.getConfiguration();
    if (JSON.stringify(newConfiguration) !== JSON.stringify(hubConfiguration)) {
        setHubConfiguration(newConfiguration);
        console.log("Hub configuration updated.");
    }
    }, [currentContainer, currentTenant]);

    /**
     * Sets the readiness flag when both the access token and hub configuration are available.
     */
    useEffect(() => {
        if (accessToken && hubConfiguration && hubConfiguration.length > 0) {
            setSignalRIsReady(true); // Ready when both token and configuration are set
        }
    }, [accessToken, hubConfiguration]);
    
    /**
     * Initializes SignalR connections when the provider is ready, or when tenant/container changes.
     */
    useEffect(() => {
        if (signalRIsReady) {
    
            const initializeConnections = async () => {
                console.log("Initializing SignalR connections...");
    
                // Clean up any existing connections when hubConfiguration changes
                await stopAllConnections();
    
                for (const hub of hubConfiguration) {
                    if (currentContainer == null || currentContainer.id == null || currentContainer.scaleId == null) {                        
                        notifyError(<>We have encountered an issue while loading container data. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
                        console.error("No container or scale id found.");
                        return;                        
                    }
                    const offset = Math.floor((Math.random() * 250) + 250); // Random offset to prevent all clients from reconnecting at the same time
                    const connection = new HubConnectionBuilder()
                    .withUrl(import.meta.env.VITE_FARMER_API_URL + hub.url, {
                        accessTokenFactory: () => accessToken, // Provide the token when needed
                    })
                    .configureLogging(LogLevel.Information)
                    .withAutomaticReconnect({
                        nextRetryDelayInMilliseconds: (retryContext) => {
                            const retryIntervals = [1000+offset, 5000+offset, 10000+offset, 20000+offset, 30000+offset];
                            return retryContext.previousRetryCount < retryIntervals.length
                                ? retryIntervals[retryContext.previousRetryCount]
                                : 30000+offset;
                        },
                    })
                    .build();

                     /*
                    *Modify connection settings to detect disconnects faster, 
                    *need improvements in backend to support this in program.cs of the web project
                    */
                    connection.serverTimeoutInMilliseconds = 15000;
                    connection.keepAliveIntervalInMilliseconds = 3000;
    
                    connectionsRef.current[hub.name] = { current: connection };
                    await startSignalRConnection(connection, notifySuccess, notifyError, removeError,setSignalRConnectionStatus); // Start the connection with retry logic
                }
            };
    
            initializeConnections();
    
            return () => {
                stopAllConnections(); // Cleanup connections when component unmounts or when dependencies change
            };
        };
    }, [hubConfiguration, signalRIsReady]);

    const stopAllConnections = async () => {
        for (const hubName in connectionsRef.current) {
            const connection = connectionsRef.current[hubName]?.current;
            if (connection) {
                console.log(`Stopping connection for hub: ${hubName}`);
                await connection.stop();
                delete connectionsRef.current[hubName];
            }
        }
    };

    /**
     * Registers a client-side function that listens for events from the server via SignalR.
     */
    const registerClientFunction = (hubName, functionName, callback) => {
        const connection = connectionsRef.current[hubName];
        if (connection && connection.current) {
            connection.current.on(functionName, callback);
            console.log(`Registering ${functionName} to ${hubName}`);
        }
        else {
            console.warn(`Connection for ${hubName} not found or not initialized.`);
        }
    };

    /**
     * Unregisters a client-side function that listens for events from the server via SignalR.
     */
    const unregisterClientFunction = (hubName, functionName, callback) => {
        const connection = connectionsRef.current[hubName];
        if (connection && connection.current) {
            connection.current.off(functionName, callback);
            console.log(`Unregistering ${functionName} from ${hubName}`);
        }
        else {
            console.warn(`Connection for ${hubName} not found or not initialized.`);
        }
    };      

    /**
     * Invokes a method on the SignalR server.
     */
    const invokeServerMethod = async (hubName, methodName, ...args) => {
        const connection = connectionsRef.current[hubName];
        if (connection && connection.current) {
            try {
                await connection.current.invoke(methodName, ...args);
            } catch (err) {
                console.error(`Error invoking ${methodName}:`, err.toString());
            }
        }
    };

    const signalRState = {
        registerClientFunction,
        unregisterClientFunction,
        invokeServerMethod,
        signalRIsReady,
        signalRIsConnected : state.signalRIsConnected,
    };

    return (
        <SignalRContext.Provider value={signalRState}>
            {children}
        </SignalRContext.Provider>
        );
});

/**
 * Custom hook to access SignalR context.
 *
 * @returns {object} SignalR context value.
 */
export const useSignalRContext = () => useContext(SignalRContext);

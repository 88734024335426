
    import React, { useContext, useEffect, useCallback, useMemo, useState } from 'react';
    import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
    import 'react-toastify/dist/ReactToastify.css';
    import Layout from './components/layout/Layout';
    import ProcessSelector from './components/layout/ProcessSelector';
    import Discarding from './components/processes/discarding/Discarding';
    import Harvesting from './components/processes/harvesting/Harvesting';
    import Reporting from './components/processes/reporting/Reporting';
    import Weighing from './components/processes/weighing/Weighing';
    import Repositioning from './components/processes/repositioning/Repositioning';
    import Seeding from './components/processes/seeding/Seeding';
    import Transplanting from './components/processes/transplanting/Transplanting';
    import './index.scss';
    import Photographing from './components/processes/photographing/Photographing';
    import TemplateManagement from './components/template-management/TemplateManagement';
    import AuthContext from './contexts/auth/AuthContext';
    import ProcessContext from './contexts/process/ProcessContext';
    import { Amplify, Auth, Hub } from 'aws-amplify';
    import SeedTypeManagementState from './contexts/seed-type-management/SeedTypeManagementState';
    import TenantManagement from './components/TenantManagement/TenantManagement';
    import GlobalUserManager from './components/GlobalUserManagement/GlobalUserManager';
    import List from './components/management/tenantUsers/List';
    import ChangePassword from './components/Authentication/ChangePassword';
    import LoginScreen from './components/ui-elements/LoginScreen';
    import { awsconfig } from './aws-config/awsconfig';
    import Loading from './components/ui-elements/Loading';
    import { ALLOWEDROLES } from './constants/roles/roles';
    import ProduceTypeRouting from './constants/routes/ProduceTypeRouting';
    import SeedTypeRouting from './constants/routes/SeedTypeRouting';
    import ContainerRouting from './constants/routes/ContainerRouting';
    import ProduceTypeProvider from './contexts/management/produceTypes/ProduceTypeProvider';
    import HarvestingProceduresProvider from './contexts/management/harvestingProcedures/HarvestingProceduresProvider';
    import SessionInformationContext from './contexts/session-information/SessionInformationContext';
    import { SignalRProvider } from './contexts/signalR/SignalRProvider';
    import HubConfigurationFactory from './utility/HubConfigurationFactory';

    const App = () => {
        const { activeProcessId, processStarted } = useContext(ProcessContext);
        const { signIn, signOut, signUp, setCurrentUser, reset, auth, loading, isAuthenticated, accessibleTenants, currentRoles, isGlobalAdmin, currentTenant, currentContainer } = useContext(AuthContext);
        const [authDone, setAuthDone] = useState(false);
        Amplify.configure(awsconfig);
        const navigate = useNavigate();
        const location = useLocation();
        const processRoutes = ['/seeding', '/transplanting', '/discarding', '/repositioning', '/harvesting', '/weighing', '/reporting', '/photo'];
        const { sessionInformation } = useContext(SessionInformationContext);

        const checkAuthState = async () => {
            if (sessionInformation && !authDone) {
                auth().catch((err) => {
                    console.error('Error while authenticating: ', err);
                    Auth.signOut().catch((err) => {
                        console.error('Error while signing out. ', err);
                    });
                }).then(() => {
                    setAuthDone(true);
                });
            };
        };

        useEffect(() => {

            checkAuthState();
            if (startsWithAny(location.pathname, processRoutes) && activeProcessId == null) {
                navigate('/');
            }
        }, [sessionInformation]);

        function startsWithAny(string, prefixes) {
            for (const prefix of prefixes) {
                if (string.startsWith(prefix)) {
                    return true;
                }
            }
            return false;
        }

        const renderRoutes = () => {
            if (isAuthenticated && authDone && !isGlobalAdmin && (!ALLOWEDROLES.some(r => currentRoles.includes(r)) || accessibleTenants.length === 0)) {
                return (
                    <div className='image'>
                        <h2>Welcome to FarmerApp</h2>
                        <p>To access any processes please ask your administrator to assign you to a tenant.</p>
                    </div>
                );
            }

            if (isAuthenticated && authDone && accessibleTenants.length > 0 && currentTenant != null) {
                return (
                    <SignalRProvider >
                        <Routes>
                            {/* Authenticated Routes */}
                            <Route path="/" element={<ProcessSelector />} />
                            <Route path="/seeding/*" element={<Seeding />} />
                            <Route path="/transplanting/*" element={<Transplanting />} />
                            <Route path="/discarding/*" element={<Discarding />} />
                            <Route path="/repositioning/*" element={<Repositioning path='/repositioning' />} />
                            <Route path="/harvesting/*" element={<Harvesting />} />
                            <Route path="/weighing/*" element={<Weighing />} />
                            <Route path="/reporting/*" element={<Reporting />} />
                            <Route path="/photo/*" element={<Photographing />} />
                            <Route path="/management/seed-types/*" element={<SeedTypeRouting />} />
                            <Route path="/management/containers/*" element={<ContainerRouting />} />
                            <Route path="/tenantmanagement/*" element={<TenantManagement />} />
                            <Route path="/templates/*" element={<TemplateManagement />} />
                            <Route path="/globalusermanagement/*" element={<GlobalUserManager />} />
                            <Route path="/management/tenantUsers/*" element={<List />} />
                            <Route path="/changepassword/*" element={<ChangePassword />} />
                            <Route path="/management/produce-types/*" element={<ProduceTypeRouting />} />
                        </Routes>
                    </SignalRProvider>
                );
            }

            return (
                <Routes>
                    {/* Public Routes */}
                    <Route path="/" element={<LoginScreen />} />
                </Routes>
            );
        };

        return (
            <HarvestingProceduresProvider>
                <SeedTypeManagementState>
                    <ProduceTypeProvider>
                        <Layout>
                            {loading && !authDone ? <Loading fullScreen /> : renderRoutes()}
                        </Layout>
                    </ProduceTypeProvider>
                </SeedTypeManagementState>
            </HarvestingProceduresProvider>
        );
    };

    export default App;



import { SET_SIGNALR_IS_CONNECTED } from '../types/contextTypes';

export default (state, action) => {
  switch (action.type) {
    case SET_SIGNALR_IS_CONNECTED:
      return {
        ...state,
        signalRIsConnected: action.payload,
      };
    default:
      break;
  }
}
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ProcessContext from '../contexts/process/ProcessContext';
import UIContext from '../contexts/ui/UIContext';
import Button from './layout/Button';


const StatusBar = () => {
  const { activeProcessId } = useContext(ProcessContext);
  const { loading } = useContext(UIContext);

  const navigate = useNavigate();

  const resetAll = () => {
    navigate('/');
  };

  return (
    <div className='sweden-status-bar'>
      <div className='status-btn-1'></div>
      <div className='status-btn-2'></div>
      <div className='status-btn-3'>
        {activeProcessId && (
          <Button
            label='Abort'
            type='inline'
            className='reset-btn'
            icon='fas fa-ban'
            disabled={loading}
            clickHandler={resetAll}
          />
        )}
      </div>
      <div className='status-btn-4'></div>
      <div className='status-btn-5'></div>
    </div>
  );
};

export default StatusBar;
